// https://paperspro.co.uk/paymentportal/index.php/payments/customers

import React from "react";
import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Loader from "./loader";
import {Link} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


export default function AllCustomers() {

    const {http} = AuthUser();
    const [cards,setCards] = useState('');


// your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            alert('Link Copied!');
        } catch (err) {
            alert('Failed to copy!');
        }
    }

    useEffect(()=>{
        fetchAllCards();
    },[]);

    const fetchAllCards = () =>{
        axios.get('https://payments.researchconsultations.com/laravel/public/index.php/api/fetchcard').then((res)=>{
            // console.log(res.data)
            setCards(res.data);
        });
    }


    function renderElement(){
        if(cards){
            let index = 1;
            return <div>
                <ToastContainer />
                <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand" href="#">Payment Cards</a>
                </nav>
                <div className="table-responsive py-4">
                    <table id="example" className="table table-bordered table-hover">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Customer Name</th>
                            {/*<th scope="col">Currency</th>*/}
                            <th scope="col">Customer Email</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cards.map(item => (
                            <tr key={item.id}>
                                <td>{index++}</td>
                                <td>{item.customer_name}</td>
                                <td>{item.customer_email}</td>
                                <td className="pl-3">
                                    <button onClick={() => copyToClipBoard('https://paperspro.co.uk/paymentportal/index.php/payments/customersPayView?cust_id='+item.cust_id+"&card_id="+item.card_id+"&card_num="+item.card_num+"&card_mon="+item.card_mon+"&card_year="+item.card_year+"&cvc="+item.cvc+"&customer_name="+item.customer_name+"&customer_email="+item.customer_email )} style={{background:"none", border:"0"}}>
                                        <i className="fa-solid fa-copy" style={{fontSize:"23px"}}></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        }else{
            return <Loader/>
        }

    }

    return(
        <div className="mt-4">
            { renderElement() }
        </div>
    )
}