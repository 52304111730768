import {useState} from 'react';
import AuthUser from "./AuthUser";
export default function Login() {
    const {http, setToken} = AuthUser();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const submitForm = () => {

        http.post('/login',{email:email,password:password}).then((res)=>{
            // console.log(res.data);
            setToken(res.data.user, res.data.access_token);
        })
    }

    return (
        <div className="row justify-content-center pt-5 ">
            <div className="col-sm-6">
                <div className="card p-5" style={{borderRadius:"20px"}}>
                    <i className="fa-solid fa-lock fa-5x" style={{color:"#23365f", textAlign: "center", paddingBottom:"20px"}}></i>
                        <div className="form-group">
                            <input type="email"
                                   className="form-control"
                                   style={{borderRadius:"20px", paddingLeft:"20px",border:"2px solid #b1b1b1"}}
                                   placeholder="Enter email"
                                   id="email"
                                   onChange={e=>setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input type="password"
                                   className="form-control"
                                   style={{borderRadius:"20px", paddingLeft:"20px",border:"2px solid #b1b1b1"}}
                                   placeholder="Enter password"
                                   id="pwd"
                                   onChange={e=>setPassword(e.target.value)}
                            />
                        </div>

                        <button type="button" onClick={submitForm}  className="btn btn-primary" style={{border:"0", borderRadius:"30px",background:"#1f3157"}} >Login</button>
                </div>
            </div>
        </div>
    )
}