import {useState} from 'react';
import AuthUser from "./AuthUser";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";


export default function CreateBrands() {

    const navigate = useNavigate();

    const {http} = AuthUser();
    const [b_name, setBrandName] = useState();
    const [url, setUrl] = useState();


    const submitForm = () => {

        http.post('brands',{b_name: b_name,url:url}).then((res)=>{
            navigate('/brands');
            toast("Brand Added Successfully");
        })
    }

    return (
        <div className="row justify-content-center pt-5">
            <div className="col-sm-6">
                <div className="card p-4">
                    <div className="form-group">
                        <label >Name:</label>
                        <input type="text"
                               className="form-control"
                               placeholder="Enter Brand Name"
                               id="b_name"
                               onChange={e=>setBrandName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label >Url</label>
                        <input type="email"
                               className="form-control"
                               placeholder="Enter Url"
                               id="url"
                               onChange={e=>setUrl(e.target.value)}
                        />
                    </div>


                    <button type="button" onClick={submitForm}  className="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    )
}