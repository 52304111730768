import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import AuthUser from "./components/AuthUser";
import Guest from "./navbar/guest";
import Auth from "./navbar/auth";
function App() {


  const {getToken} = AuthUser();

  if (!getToken()){
    return <Guest/>
  }

  $(document).ready(function () {
    setTimeout(function(){
      $('#example').DataTable();
    } ,1000);
  });



  return (
    <Auth/>
  );
}

export default App;
