import React, {useEffect} from "react";
import {useState} from 'react';
import AuthUser from "./AuthUser";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "./loader";
import axios from "axios";


export default function Frontpay(props) {

    const navigate = useNavigate();

    const {http} = AuthUser();

    const [amount, setAmount] = useState('');
    const [amountConvert, setamountConvert] = useState('');
    const [currency, setCurrency] = useState('');
    const [brandName, setBrandName] = useState('');
    const [service, setService] = useState('');
    const [salesName, setSalesName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');


    useEffect(()=>{
        fetchCurrencyAndConvert();
    },[]);

    const fetchCurrencyAndConvert = () =>{
        axios.get('https://api.exchangerate-api.com/v4/latest/gbp').then((res)=>{
            setamountConvert(res.data.rates.USD);
        });

    }

    const submitForm = () => {

        // console.log(amount+' '+currency+' '+brandName+' '+service+' '+salesName+' '+customerName+' '+customerEmail)


        http.post('https://portal.frontpay.pk/api/create-token',
            {fp_merchant_id: '0mHm8gGxi9',
                  fp_merchant_secret: 'P9I22LGkFbqRDcj',
            }).then((res)=>{
                // console.log(res.data.token)
            // let last_id = res.data;
            // Post data to selected brand
            createOrder(res.data.token);
        })
    }

    function createOrder(res_token) {


        let form = new FormData();
        form.append("amount", (amount * amountConvert) );
        form.append("transaction_reference", Math.floor(Math.random() * 1000000000));
        form.append("currency", "USD");
        form.append("mode", "LIVE");


        console.log(res_token)
        fetch('https://portal.frontpay.pk/api/create-order', {
            method: 'POST',
            body: form,
            headers:{
                "Authorization":"Bearer "+res_token,
            },
        }).then(function (response) {
            if (response.ok) {
                let json = response.json();
                return json;
            }
            return Promise.reject(response);
        }).then(function (data) {
            console.log((data.result.payment_url));

            savePaymentDetails(data.result.payment_url);

        }).catch(function (error) {
            console.warn('Something went wrong.', error);
        });
        //
        navigate('/frontpaylists');
        toast("Link Created Successfully For "+customerName);
    }


    function savePaymentDetails (paymentLink) {

        http.post('frontpay',
            {amount: amount,currency:currency, brand:brandName,
                service:service,sales_representative_name:salesName,
                customer_name: customerName, customer_email: customerEmail,payment_link:paymentLink,
            }).then((res)=>{
                console.log(res)
        })
    }

    function renderElement() {
        if(props.brandsList){
            return (
                <div className="form-style mt-5 container">
                    <span className="pt-3">+Create Payment Link+</span>
                    <div className="row  pl-5 pr-5 pb-5 mt-3">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label >Amount:</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter Amount"
                                       id="amount"
                                       onChange={e=>setAmount(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <div className="form-group" id="currency">
                                    <label >Currency</label>
                                    <select className="form-control" onChange={e=>setCurrency(e.target.value)}>
                                        <option value="">Select Currency</option>
                                        <option value="GBP">GBP</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <div className="form-group" id="brand">
                                    <label>Brand</label>
                                    <select className="form-control" onChange={e=>setBrandName(e.target.value)}>
                                        <option value="">Select Brand</option>
                                        {props.brandsList.map(item => (
                                            <option key={item.id} value={item.url}>{item.b_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group">
                                <div className="form-group" id="services">
                                    <label >Select Service</label>
                                    <select className="form-control" onChange={e=>setService(e.target.value)}>
                                        <option value="">Select Services</option>
                                        <option value="Writing">Writing</option>
                                        <option value="Course Work">Course Work</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group">
                                <label >Sales Representative Name:</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter Sales Representative Name"
                                       id="sales_representative_name"
                                       onChange={e=>setSalesName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group">
                                <label >Customer Name</label>
                                <input type="text"
                                       className="form-control"
                                       placeholder="Enter Customer Name"
                                       id="customer_name"
                                       onChange={e=>setCustomerName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label >Customer Email</label>
                                <input type="email"
                                       className="form-control"
                                       placeholder="Enter Customer Email"
                                       id="customer_email"
                                       onChange={e=>setCustomerEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 text-center">
                            <button type="button" onClick={submitForm}  className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>

            )
        }else {
            return <Loader/>
        }
    }


    return (
        <div className="mt-4">
            { renderElement() }
        </div>
    )
}