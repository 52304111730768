import React, {useEffect} from "react";
import {useState} from 'react';
import AuthUser from "./AuthUser";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "./loader";
import axios from "axios";


export default function CreateLink(props) {
    const navigate = useNavigate();

    const {http} = AuthUser();
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('');
    const [brandName, setBrandName] = useState('');
    const [service, setService] = useState('');
    const [salesName, setSalesName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');



    const submitForm = () => {

    // console.log(amount+' '+currency+' '+brandName+' '+service+' '+salesName+' '+customerName+' '+customerEmail)


        http.post('links',
            {amount: amount,currency:currency, brand:brandName,
                  service:service,sales_representative_name:salesName,
                  customer_name: customerName, customer_email: customerEmail,
            }).then((res)=>{
                let last_id = res.data;
            // Post data to selected brand
            axios.post(brandName+`/paymentportal/index.php/api/payments/store/`+last_id,
                {amount: amount,payment_id:last_id,currency:currency, brand:brandName,
                    service:service,sales_representative_name:salesName,
                    customer_name: customerName, customer_email: customerEmail,
                })
                .then(res => {
                    console.log(res);
                    console.log(res.data);
                })

            navigate('/links');
            toast("Link Created Successfully For "+customerName);
        })
    }

    function renderElement() {
        if(props.brandsList){
        return (
            <div className="form-style mt-5 container">
                <span className="pt-3">+Create Payment Link+</span>
                <div className="row  pl-5 pr-5 pb-5 mt-3">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label >Amount:</label>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Enter Amount"
                                   id="amount"
                                   onChange={e=>setAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <div className="form-group" id="currency">
                                <label >Currency</label>
                                <select className="form-control" onChange={e=>setCurrency(e.target.value)}>
                                    <option value="">Select Currency</option>
                                    <option value="GBP">GBP</option>
                                    <option value="USD">USD</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <div className="form-group" id="brand">
                                <label>Brand</label>
                                <select className="form-control" onChange={e=>setBrandName(e.target.value)}>
                                    <option value="">Select Brand</option>
                                    {props.brandsList.map(item => (
                                    <option key={item.id} value={item.url}>{item.b_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <div className="form-group" id="services">
                                <label >Select Service</label>
                                <select className="form-control" onChange={e=>setService(e.target.value)}>
                                    <option value="">Select Services</option>
                                    <option value="Writing">Writing</option>
                                    <option value="Course Work">Course Work</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label >Sales Representative Name:</label>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Enter Sales Representative Name"
                                   id="sales_representative_name"
                                   onChange={e=>setSalesName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-group">
                            <label >Customer Name</label>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Enter Customer Name"
                                   id="customer_name"
                                   onChange={e=>setCustomerName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <label >Customer Email</label>
                            <input type="email"
                                   className="form-control"
                                   placeholder="Enter Customer Email"
                                   id="customer_email"
                                   onChange={e=>setCustomerEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="col-sm-12 text-center">
                        <button type="button" onClick={submitForm}  className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>

        )
        }else {
            return <Loader/>
        }
    }


        return (
            <div className="mt-4">
                { renderElement() }
            </div>
    )
}