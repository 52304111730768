import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import AuthUser from "../components/AuthUser";
import HomeRoutes from "../routes/home";
function Auth() {

    const {token, logout} = AuthUser();

    const [items, setItems] = useState([]);

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('user'));
        if (items) {
            setItems(items);
        }
    }, []);

    const logoutUser = ()=> {
        if (token !== undefined) {
            logout();
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
                <Link to="/" className="navbar-brand" >Navbar</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav" style={{width:"100%", justifyContent:"center"}}>
                        {/*<Link className="nav-item nav-link" to="/">Home</Link>*/}
                        <Link className="nav-link" to="/links">Stripe Links</Link>
                        <Link className="nav-link" to="/frontpaylists">Frontpay Links</Link>

                        {/*<Link className="nav-link" to="/dashboard">Dashboard</Link>*/}
                        <Link className="nav-link" to="/brands">Brands</Link>
                        { items.email === 'admin@cloudstafftechnologies.com' ? (
                            <Link className="nav-link" to="/customers">Cards</Link>
                        ): '' }
                        {/*<Link className="nav-link" to="/createLink">Create Link</Link>*/}
                        <li className="nav-item">
                            <span role="button" className="nav-link" onClick={logoutUser} >Logout</span>
                        </li>
                    </div>
                </div>
            </nav>


            <div className="container-fluid">
                <HomeRoutes />
            </div>
        </>
    );
}

export default Auth;
