// import React from "react";
import {Link} from 'react-router-dom';

import GuestRoutes from "../routes/guest";
import React from "react";

function Guest() {
    return (
        <>

            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-5">
                <Link className="navbar-brand" to="/">Navbar</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav" style={{width:"100%", justifyContent:"center"}}>
                        <li className="nav-item">
                            {/*<Link className="nav-link" to="/">Home</Link>*/}
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/register">Register</Link>
                        </li>
                    </div>
                </div>
            </nav>

            <div className="container">
                <GuestRoutes />
            </div>
        </>
    );
}

export default Guest;
