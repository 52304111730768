import React from "react";
import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Loader from "./loader";
import {Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Brands() {

    const {http} = AuthUser();
    const [brands,setBrands] = useState('');

    useEffect(()=>{
        fetchBrands();
    },[]);

    const fetchBrands = () =>{
        http.get('brands').then((res)=>{
            setBrands(res.data);
        });

    }

    const deleteBrands = (id) => {
        http.delete('brands/'+id).then(res=> {
            fetchBrands();
            toast("Brand Deleted Successfully");
        })
    }

    function renderElement(){
        if(brands){
            let index = 1;
            return <div className="container">
                <ToastContainer />
                <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand" href="#">Links</a>
                    <Link className="btn btn-primary" to="/createBrand">Create Brand</Link>
                </nav>
                <div className="table-responsive py-4">
                    <table id="example" className="table table-bordered table-hover">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Brand Name</th>
                            <th scope="col">Brand Url</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {brands.map(item => (
                            <tr key={item.id}>
                                <td>{index++}</td>
                                <td>{item.b_name}</td>
                                <td>{item.url}</td>
                                <td className="pl-4">
                                    <button type="button" style={{border:"0", background:"none"}} onClick={()=>{deleteBrands(item.id)}} >
                                        <i className="fa-solid fa-trash" style={{color:"red"}}>
                                        </i></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        }else{
            return <Loader/>
        }

    }

    return(
        <div className="mt-4">
            { renderElement() }
        </div>
    )
}