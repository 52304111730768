import React, {useEffect, useState} from "react";
import {Routes, Route} from 'react-router-dom';
import Home from "../components/home";
import Dashboard from "../components/dashboard";
import CreateBrands from "../components/createBrands";
import Brands from "../components/brands";
import CreateLink from "../components/createLink";
import Frontpay from "../components/Frontpay";
import LinkLists from "../components/LinkLists";
import AuthUser from "../components/AuthUser";
import FrontpayList from "../components/FrontpayList";
import AllCustomers from "../components/AllCustomers";

function HomeRoutes() {

    const {http} = AuthUser();
    const [brands,setBrands] = useState('');

    useEffect(()=>{
        fetchBrands();
    },[]);

    const fetchBrands = () =>{
        http.get('brands').then((res)=>{
            setBrands(res.data);
        });

    }

    return (

        <div>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/dashboard" element={<Dashboard/>} />
                <Route path="/customers" element={<AllCustomers brandsList={brands} />} />
                <Route path="/createBrand" element={<CreateBrands/>} />
                <Route path="/frontpay" element={<Frontpay brandsList={brands} />} />
                <Route path="/brands" element={<Brands/>} />
                <Route path="/createLink"  element={<CreateLink brandsList={brands} />} />
                <Route path="/links" element={<LinkLists/>} />
                <Route path="/frontpaylists" element={<FrontpayList/>} />

            </Routes>
        </div>
    );
}

export default HomeRoutes;
