import React from "react";
import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import Loader from "./loader";
import {Link} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function FrontpayList() {

    const {http} = AuthUser();
    const [links,setLinks] = useState('');


// your function to copy here

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            alert('Link Copied!');
        } catch (err) {
            alert('Failed to copy!');
        }
    }

    useEffect(()=>{
        fetchLinks();
    },[]);

    const fetchLinks = () =>{
        http.get('frontpay').then((res)=>{
            console.log(res.data)
            setLinks(res.data);
        });
    }


    function renderElement(){
        if(links){
            let index = 1;
            return <div>
                <ToastContainer />
                <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand" href="#">Payment Links</a>
                    <Link className="btn btn-primary" to="/frontpay">Create Link</Link>
                </nav>
                <div className="table-responsive py-4">
                    <table id="example" className="table table-bordered table-hover">
                        <thead className="thead-dark">
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Amount</th>
                            {/*<th scope="col">Currency</th>*/}
                            <th scope="col">Brand</th>
                            <th scope="col">Sales Men</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Customer Email</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {links.map(item => (
                            <tr key={item.id} >
                                <td>{index++}</td>
                                <td>{item.amount}</td>
                                {/*<td>{item.currency}</td>*/}
                                <td>{item.brand}</td>
                                <td>{item.sales_representative_name}</td>
                                <td>{item.customer_name}</td>
                                <td>{item.customer_email}</td>
                                <td className="pl-3">
                                    <button onClick={() => copyToClipBoard(item.payment_link)} style={{background:"none", border:"0"}}>
                                        <i className="fa-solid fa-copy" style={{fontSize:"23px"}}></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        }else{
            return <Loader/>
        }

    }

    return(
        <div className="mt-4">
            { renderElement() }
        </div>
    )
}